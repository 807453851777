import React from 'react';
import {useSelector} from "react-redux";
import {MainLogoImg, MainLogoImgWrapper, EmptyLogoWrapper} from "../../containers/Main/style";
import {Icon} from "../Icon";
import marysiaLogo from "../../assets/marysia-logo-large-black.png";
import doucheBagsLogo from "../../assets/douchebags_logo_black.png";
import byMalinaLogo from "../../assets/bymalina-logo-white.png";
import eightyEightyFortyEightyLogo from '../../assets/8848_Logo_pos.svg';
import chimiLogo from '../../assets/chimi_logo.svg';
import mustoLogo from '../../assets/musto_logo.svg';
import rdLogo from '../../assets/rd_logo.svg';
import bbLogo from '../../assets/bb_logo.png';
import hhLogo from '../../assets/hh_logo.svg';
import ogoshipLogo from '../../assets/ogoship-logo.png';
import totemeLogo from '../../assets/toteme-logo.png';
import asketLogo from '../../assets/asket_logo.svg';
import indiskaLogo from '../../assets/indiska_logo.svg';
import quizLogo from '../../assets/quiz_logo.svg';
import stutterheimLogo from '../../assets/stutterheim_logo.svg';
import shoptshopenLogo from '../../assets/sportshopen-logo.svg';
import shoptshopenOutletLogo from '../../assets/sportshopen-outlet-logo.svg';
import swedemountLogo from '../../assets/swedemount-logo.svg';
import arenaLogo from '../../assets/arena_black-in-white.svg';
import {merchantThemes} from "../Variable";

const MerchantLogo = ({ isOpen }) => {
    const { currentTheme, shopProperties, locale } = useSelector(({root}) => ({
        currentTheme: root.currentTheme,
        shopProperties: root.shopProperties,
        locale: root.locale
    }));
    const themeName = currentTheme && currentTheme.name ? currentTheme.name : '';
    /**
     * getLogoHref {Function}
     * @description - get current logo href based on locale and shop settings
     * @return {String}
     *
     * */
    const getLogoHref = (locale) => {
        let urls = shopProperties['widget.logo.urls'] || null;
        let urlArr = urls ? urls.split(',') : null;
        let hrefsMap = {}

        if(urlArr) {
            urlArr.forEach((hrefItem)=>{
                let currentUrlArr = hrefItem.split('_');
                hrefsMap[currentUrlArr[0]] = currentUrlArr[1]
            })
        }

        return hrefsMap[locale] ? hrefsMap[locale] : null
    };
    let logoUrl = getLogoHref(locale)
    let logotypes = {
        [merchantThemes.marysia] : marysiaLogo,
        [merchantThemes.douchebags] : doucheBagsLogo,
        [merchantThemes.bjornborg] : bbLogo,
        [merchantThemes.royal_design] : rdLogo,
        [merchantThemes.rum21] : 'https://returnado-static.s3-eu-west-1.amazonaws.com/rum_21_logo.png',
        [merchantThemes.bymalina] : byMalinaLogo,
        [merchantThemes["8848"]] : eightyEightyFortyEightyLogo,
        [merchantThemes.chimi] : chimiLogo,
        [merchantThemes.musto] : mustoLogo,
        [merchantThemes.woodwood] : 'icon',
        [merchantThemes.aycane] : 'empty',
        [merchantThemes.henrylloyd] : 'empty',
        [merchantThemes.hh] : hhLogo,
        [merchantThemes.hh_ww] : hhLogo,
        [merchantThemes.ogoship] : ogoshipLogo,
        [merchantThemes.toteme] : totemeLogo,
        [merchantThemes.asket] : asketLogo,
        [merchantThemes.sns] : 'https://returnado-static.s3.eu-west-1.amazonaws.com/sns/sns-logo.svg',
        [merchantThemes.quiz] : quizLogo,
        [merchantThemes.indiska] : indiskaLogo,
        [merchantThemes.stutterheim] : stutterheimLogo,
        [merchantThemes.sportshopen]: shoptshopenLogo,
        [merchantThemes.sportshopen_outlet]: shoptshopenOutletLogo,
        [merchantThemes.swedemount]: swedemountLogo,
        [merchantThemes.arena]: arenaLogo
    }

    let getLogo = (theme) => {
        let currentTheme = logotypes[theme];
        switch (logotypes[theme]) {
            case 'empty':
                return <EmptyLogoWrapper/>;
            case 'icon':
                return <MainLogoImgWrapper isOpen={isOpen} themeName={themeName} noImage>
                    <Icon component={'woodWoodLogo'} />
                </MainLogoImgWrapper>;
            default:
                return logoUrl ? <a href={logoUrl} target='_blank' rel="noreferrer">
                    <MainLogoImg isOpen={isOpen} src={currentTheme} />
                </a> : <MainLogoImg isOpen={isOpen} src={currentTheme} />
        }
    }


    return (
        <div>
            { getLogo(themeName)}
        </div>
    )
}
export default MerchantLogo
